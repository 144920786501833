import { BrowserRouter as Router, Routes,Route } from 'react-router-dom';
import Home from './appCode/pages/Home/Home';
import About from './appCode/pages/About/about';
import Bank from './appCode/pages/Bank/bank';

function App() {
  return (
    <Router>
    <div>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/about" element={<About />} />
      </Routes>
    </div>
  </Router>
  );
}

export default App;
