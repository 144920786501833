import { BrowserRouter as Router, Routes,Route } from 'react-router-dom';
import { useState } from 'react';
import emailjs from '@emailjs/browser';
import './home.css'
import Ring from '../../component/ring/ring';
import Header from '../../component/header/header';
import Footer from '../../component/footer/footer';
import Loading from '../../component/loading/loading';

function Home() {
const [name, setName] = useState('');
const [birth, setBirth] = useState('');
const [phone, setPhone] = useState('');
const [country, setCountry] = useState('');
const [many,setMany] = useState('');
const [works,setWorks] = useState(null);
const [credit,setCredit] = useState(null);
const [money,setMoney] = useState('');
const [state,setState] = useState(false)
const [nameError, setNameError] = useState('');
const [birthError, setBirthError] = useState('');
const [phoneError, setPhoneError] = useState('');
const [countryError, setCountryError] = useState('');
const [manyError,setManyError] = useState('');
const [worksError,setWorksError] = useState(null);
const [creditError,setCreditError] = useState(null);
const [moneyError,setMoneyError] = useState(null);
const [loadState,setLoadState] = useState(false)

const handleRadioChange = (value) => {
    setWorks(works === value ? null : value);
    setWorksError('')
                  setState(false)

  };
  const handleRadioChange1 = (value) => {
      setCredit(credit === value ? null : value);
      setCreditError('')
                    setState(false)

    };
const onClick = async () =>{
if(!name){
 setNameError('Խնդրում ենք լրացնել այս դաշտը:')
 setState(false)
}
if(!birth){
 setBirthError('Խնդրում ենք լրացնել այս դաշտը:')
  setState(false)

}
if(!money){
 setMoneyError('Խնդրում ենք լրացնել այս դաշտը:')
  setState(false)

}
if(!phone){
 setPhoneError('Խնդրում ենք լրացնել այս դաշտը:')
  setState(false)

}
if(!works){
 setWorksError('Խնդրում ենք լրացնել այս դաշտը:')
  setState(false)

}
if(!credit){
 setCreditError('Խնդրում ենք լրացնել այս դաշտը:')
  setState(false)

}
if(name && birth && phone && works && credit && money) {
        handle()
}
}

const handle = async () =>{
setLoadState(true)
 const payload = {
      nameSurname: name,
      phone: phone,
     history:credit,
     amount:money,
     work_type:works,
     country:country,
     date:birth,
      salary: many ? +many :  0
    };

  try {
      const response = await fetch('https://vida.vidacredit.am/send-email', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(payload),
      });
   setName('')
    setBirth('')
    setPhone('')
    setCountry('')
    setMany('')
    setWorks(null)
    setCredit(null)
    setMoney('')
    setLoadState(false)
    setState(true)

        } catch (error) {
        setLoadState(false)
      console.log(error)
    }
}

  return (
    <div >
      <Header/>
      <div className="inputContainer">
      <input
          placeholder="Անուն Ազգանուն"
          type="text"
          className="inputCont"
          onChange={e =>{
          setName(e.target.value)
          setNameError('')
              setState(false)
          }}
          value={name}
       />
       {nameError ? <span className={"error"}>{nameError}</span> : null}
       <input
                  placeholder="Ծննդյան օր/ամիս/տարի"
                  type="text"
                  className="inputCont"
                  onChange={e =>{
                  setBirth(e.target.value)
                  setBirthError('')
                                setState(false)

                  }}
                  value={birth}
        />
        {birthError ? <span className={"error"}>{birthError}</span> : null}
        <div className='labelContainer'>
        <div className='labelCont'>
       <select
        className="selectCont"
          onChange={e => {
          setCountry(e.target.value)
                        setState(false)

          }}
                                   value={country}
       >
       <option value disabled="disabled">Ընտրել Մարզը</option>
        <option value='Երևան'>Երևան</option>
        <option value='Արագածոտն'>Արագածոտն</option>
        <option value='Արարատ'>Արարատ</option>
        <option value='Արմավիր'>Արմավիր</option>
        <option value='Գեղարքունիք'>Գեղարքունիք</option>
        <option value='Կոտայք'>Կոտայք</option>
        <option value='Լոռի'>Լոռի</option>
        <option value='ԼՂՀ'>ԼՂՀ</option>
        <option value='Շիրակ'>Շիրակ</option>
        <option value='Սյունիք'>Սյունիք</option>
        <option value='Տավուշ'>Տավուշ</option>
        <option value='Վայոց ձոր'>Վայոց ձոր</option>
       </select>
       </div>
       </div>
         <input
                  placeholder="Հեռախոս"
                  className="inputCont"
                  type="text"
                  onChange={e => {
                  setPhone(e.target.value)
                  setPhoneError('')
                                setState(false)

                  }}
                 value={phone}
         />
                 {phoneError ? <span className={"error"}>{phoneError}</span> : null}
   <input
                  placeholder="Գումարի չափ"
                  type="text"
                  className="inputCont"
                  onChange={e =>{
                  setMoney(e.target.value)
                  setMoneyError('')
                                setState(false)

                  }}
                  value={money}
        />
                         {moneyError ? <span className={"error"}>{moneyError}</span> : null}

       </div>
       <div>
          <div className='historyContainer'>
                    <div className='historyChild'>
                  <h3>Վարկային պատմության կարգավիճակը</h3>
                  <span>
                      <label className='labelStyle'>
                        <input
                        type='radio'
                        className='spanTextCont'
                        checked={credit === 'Չունեմ վարկային պատմություն'}
                        onChange={() => handleRadioChange1('Չունեմ վարկային պատմություն')}
                        />
                        <span className='textVarks'>Չունեմ վարկային պատմություն</span>
                      </label>
                        <label className='labelStyle'>
                        <input
                        type='radio'
                        className='spanTextCont'
                        checked={credit === 'Ունեմ դրական վարկային պատմություն'}
                        onChange={() => handleRadioChange1('Ունեմ դրական վարկային պատմություն')}
                        />
                        <span className='textVarks'>Ունեմ դրական վարկային պատմություն</span>
                       </label>
                       <label className='labelStyle'>
                        <input
                        type='radio'
                        className='spanTextCont'
                        checked={credit === 'Ունեմ ծանրաբեռնված վարկային պատմություն'}
                        onChange={() => handleRadioChange1('Ունեմ ծանրաբեռնված վարկային պատմություն')}
                        />
                        <span className='textVarks'>Ունեմ ծանրաբեռնված վարկային պատմություն</span>
                      </label>
                      <label className='labelStyle'>
                        <input
                        type='radio'
                        className='spanTextCont'
                        checked={credit === 'Մարել եմ ուշացումները, լրացել է մեկ տարին'}
                        onChange={() => handleRadioChange1('Մարել եմ ուշացումները, լրացել է մեկ տարին')}
                        />
                        <span className='textVarks'>Մարել եմ ուշացումները, լրացել է մեկ տարին</span>
                      </label>
                  </span>
                  </div>
          </div>
          <div className='inputContainer1'>
                           {creditError ? <span className={"error"}>{creditError}</span> : null}
           </div>
   <div className='historyContainer'>
                    <div className='historyChild'>
                  <h3>Գրանցված աշխատանք</h3>
                  <span>
                      <label className='labelStyle'>
                        <input
                        type='radio'
                        className='spanTextCont'
                          checked={works === 'Այո'}
                          onChange={() => handleRadioChange('Այո')}
                        />
                        <span className='textVarks'>Այո</span>
                      </label>
                        <label className='labelStyle'>
                        <input
                        type='radio'
                        className='spanTextCont'
                        checked={works === 'Ոչ'}
                        onChange={() => {
                        setMany('')
                        handleRadioChange('Ոչ')
                        }}
                        />
                        <span className='textVarks'>Ոչ</span>
                       </label>
                       <label className='labelStyle'>
                        <input
                        type='radio'
                        className='spanTextCont'
                        checked={works === 'Այո ՝ ուժային կառույցում'}
                        onChange={() => handleRadioChange('Այո ՝ ուժային կառույցում')}
                        />
                        <span className='textVarks'>Այո ՝ ուժային կառույցում</span>
                      </label>
                  </span>
                  </div>
          </div>
          {works === 'Այո ՝ ուժային կառույցում' || works === 'Այո' ?
   <div className='inputContainer1'>
           <input
                         placeholder="Գրանցված աշխատավարձ ՀՀ Դրամ"
                            type="text"
                            className="inputCont"
                            onChange={e => setMany(e.target.value)}
                            value={many}
                  />
                            </div>
          :null}
                              <div className='inputContainer1'>
                                                       {worksError ? <span className={"error"}>{worksError}</span> : null}
                                       </div>
          <div className="btnContainer">
          {!loadState ?
            <input
                    class="wpcf7-form-control wpcf7-submit has-spinner"
                     type="submit"
                     value="Ուղարկել հայտը"
                      className='btnChild'
                      onClick={onClick}
                      /> : null
          }

          </div>
          {nameError || birthError || phoneError || worksError || creditError ?
            <div className="btnContainer">
                                <span className={'errorAll'}>Մեկ կամ մի քանի դաշտեր ունեն սխալ: Խնդրում ենք ստուգել և նորից փորձել:</span>
                    </div>
          : null}
    {state  ?
            <div className="btnContainer">
                                <span className={'errorAll1'}>Շնորհակալություն։ Ձեր հայտը ողղարկվել է` սպասեք մասնագետի զանգին:</span>
                    </div>
          : null}
                  {loadState ?
                    <div className="btnContainer3">
                                                 <Loading/>
                                      </div>
                                      : null
                  }
       </div>

             <Footer/>
      <Ring/>
    </div>
  );
}

export default Home;
