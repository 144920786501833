import { BrowserRouter as Router, Routes,Route } from 'react-router-dom';
import './footer.css'
import facebook from '../../images/facebook.webp'
function Footer() {
  return (
  <div className="Cont">
      <div className='containerFooter'>
        <a href='https://www.facebook.com/VidaCreditArmenia'>
        <img src={facebook} className='facebook'/>
        </a>
     <div>
      <div>
      Copyright © 2024
      </div>
      <a href='tel:+37494443166' className='phone'>
      +374 94 443 166
      </a>
     </div>
      </div>
      </div>
  );
}

export default Footer;