import React, { useState, useEffect } from 'react';
import './loading.css'; // We'll define a CSS spinner in this file

function Loading() {

  return (
    <div>
        <div className="spinner"></div>
    </div>
  );
}

export default Loading;