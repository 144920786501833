import {BrowserRouter as Router, Routes, Route} from 'react-router-dom';
import './header.css'
import Logo from '../../images/log.png'

function Header() {
    return (
        <header className='site-header'>
            <div className='navChild'>
                <a href='/'>
                    <img src={Logo} alt='Vida Logo' className='logoStyle'/>
                </a>
                <div className="ddd">
                    <a href='/about' className='textHeader'>Մեր Մասին</a>
                </div>
            </div>
        </header>
    );
}

export default Header;

