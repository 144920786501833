import { BrowserRouter as Router, Routes,Route } from 'react-router-dom';
import './about.css'
import Header from '../../component/header/header';
import Footer from '../../component/footer/footer';
import imgBack from '../../images/dollar.png'

function About() {
  return (
    <div>
    <Header/>
    <div className="imgCont">
    <img src={imgBack} className='backroundImage'/>
    <h3 className='abs'>Մեր Մասին</h3>
    </div>
    <div className='textAboutContainer'>
     <p className='textAbout'>
           Կազմակերպությունը վարկեր չի տրամադրում, չի հանդիսանում վարկային կազմակերպություն, չի գովազդում և չի գովազդել վարկերի տրամադրմանը վերաբերվող հայտարարություններ։ Մենք տրամադրում ենք ֆինանսական խորհրդատվություն, մատուցում ենք ծառայություններ` արագ վարկերի ստացման մասով , առաջարկում ենք ընտրել ցածր տոկոսադրույքով արագ գումար, օգնում ենք Ձեզ գտնել լուծումներ, ստեղծված ֆինանսական խնդիրների լուծման համար։
     </p>
    </div>
     <Footer/>
    </div>
 
  );
}

export default About;