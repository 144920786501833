import { BrowserRouter as Router, Routes,Route } from 'react-router-dom';
import './ring.css'
function Ring() {
  return (
      <a className='btn-call' href='tel:+37494443166'>
        <div className="btn-call__ico">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" width="20px">
      <path fill="#ffffff" d="M497.4 361.8l-112-48a24 24 0 0 0 -28 6.9l-49.6 60.6A370.7 370.7 0 0 1 130.6 204.1l60.6-49.6a23.9 23.9 0 0 0 6.9-28l-48-112A24.2 24.2 0 0 0 122.6 .6l-104 24A24 24 0 0 0 0 48c0 256.5 207.9 464 464 464a24 24 0 0 0 23.4-18.6l24-104a24.3 24.3 0 0 0 -14-27.6z"></path>
      </svg>
        </div>
      </a>
  );
}

export default Ring;